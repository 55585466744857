import { useCallback, useMemo, useState } from 'react';

import CompositeSelection from 'bloko/blocks/compositeSelection';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import AreaId from 'HHC/areaId';
import { SUPERNOVA_SEARCH_FORM_ID } from 'src/components/SupernovaSearch';
import translation from 'src/components/translation';
import { useAreasDataProvider } from 'src/hooks/useAreasDataProvider';
import useFetchAreaTree from 'src/hooks/useFetchAreaTree';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import styles from './filters.less';

const TrlKeys = {
    selectRegion: 'vacancySearch.selectregionfromlist',
    submit: 'treeselector.save',
    cancel: 'treeselector.cancel',
    search: 'treeselector.quicksearch',
    notFound: 'treeselector.notFound',
};

const AreaSelection: TranslatedComponent<{ areaId?: string }> = ({ trls, areaId }) => {
    const isZarplata = useIsZarplataPlatform();
    const fetcherArgs = isZarplata ? { queryParams: { rootAreaId: AreaId.Russia } } : {};
    useFetchAreaTree(fetcherArgs);
    const dataProvider = useAreasDataProvider(true);
    const areaTree = useSelector((state) => state.areaTree);
    const [items, setItems] = useState(areaId ? [areaId] : []);
    const collection = useMemo(() => fromTree(areaTree), [areaTree]);

    const defaultCountryCompanySearchId = useSelector(
        ({ defaultCountryCompanySearchId }) => defaultCountryCompanySearchId
    );
    const defaultValue = `${defaultCountryCompanySearchId ?? ''}`;

    const handleChange = useCallback(
        (items: string[]) => {
            setItems(items);
        },
        [setItems]
    );

    return (
        <>
            <input
                form={SUPERNOVA_SEARCH_FORM_ID}
                type="hidden"
                name="areaId"
                value={items.length ? items[0] : defaultValue}
            />
            <CompositeSelection
                collection={collection}
                singleChoice
                value={items}
                onChange={handleChange}
                trl={{
                    submit: trls[TrlKeys.submit],
                    cancel: trls[TrlKeys.cancel],
                    searchPlaceholder: trls[TrlKeys.search],
                    notFound: trls[TrlKeys.notFound],
                }}
            >
                {({ renderInput }) => (
                    <>
                        <div className={styles.region}>
                            {renderInput({
                                suggest: true,
                                suggestProps: {
                                    dataProvider,
                                    // eslint-disable-next-line react/display-name
                                    itemContent: (item) => <span data-qa="suggester__keywords-item">{item.text}</span>,
                                },
                                'data-qa': 'employers-list-regions-input',
                            })}
                        </div>
                    </>
                )}
            </CompositeSelection>
        </>
    );
};

export default translation(AreaSelection);
