import { FC } from 'react';

import Column from 'bloko/blocks/column';

import PageLayout, { SideBarLayout } from 'src/app/layouts/PageLayout';
import ApplicantSideBar from 'src/components/ApplicantSideBar';
import { useSelector } from 'src/hooks/useSelector';

import Alphabet from 'src/pages/EmployersList/components/Alphabet';
import Companies from 'src/pages/EmployersList/components/Companies';
import EmployerRegistrationButton from 'src/pages/EmployersList/components/EmployerRegistrationButton';
import Filters from 'src/pages/EmployersList/components/Filters';

const EmployersList: FC = () => {
    const { title, description } = useSelector((state) => state.pageMetaData);

    return (
        <PageLayout title={title} description={description} layout={SideBarLayout} sidebar={ApplicantSideBar}>
            <Column m="8" l="11">
                <EmployerRegistrationButton />
                <Filters />
                <Alphabet />
                <Companies />
            </Column>
        </PageLayout>
    );
};

export default EmployersList;
